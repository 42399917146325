import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 399.000000 384.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,384.000000) scale(0.100000,-0.100000)">

<path d="M0 1920 l0 -1920 1995 0 1995 0 0 1920 0 1920 -1995 0 -1995 0 0
-1920z m2836 1781 c60 -34 94 -92 94 -161 0 -67 -22 -113 -73 -154 -34 -27
-45 -30 -150 -37 -62 -4 -163 -15 -223 -23 -508 -71 -966 -360 -1257 -794
-107 -160 -191 -346 -241 -535 -30 -113 -43 -197 -61 -393 -11 -131 -16 -156
-36 -184 -39 -52 -86 -75 -154 -75 -110 0 -183 71 -192 187 -12 160 48 508
128 738 54 157 216 460 302 566 8 10 39 48 68 84 129 161 346 351 539 474 238
152 571 272 855 311 230 31 341 30 401 -4z m-6 -711 c43 -22 81 -72 100 -133
10 -33 9 -46 -5 -85 -35 -95 -89 -127 -225 -136 -219 -14 -363 -60 -557 -177
-142 -86 -285 -241 -377 -410 -70 -127 -125 -328 -126 -452 0 -102 -16 -151
-63 -197 -129 -124 -317 -28 -317 163 0 186 68 445 166 636 134 260 325 462
579 613 56 33 87 48 190 93 51 22 125 45 125 38 0 -3 6 -1 13 6 8 6 60 19 118
30 138 26 150 28 252 30 70 1 96 -3 127 -19z m-252 -897 c-10 -2 -26 -2 -35 0
-10 3 -2 5 17 5 19 0 27 -2 18 -5z m270 -143 c7 -11 11 -20 9 -20 -2 0 -12 9
-22 20 -10 11 -14 20 -9 20 5 0 15 -9 22 -20z m69 -182 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m0 -110 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-77 -161 c0 -2 -10 -12 -22 -23 l-23 -19 19 23 c18 21 26 27
26 19z m-550 -28 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10
-11 10 -16z m263 -116 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-1835 -274 c39 -18 42 -22 42 -59 0 -45 -15 -52 -32 -15 -25 54 -126 85 -162
50 -8 -8 -18 -15 -23 -15 -39 0 -69 -189 -39 -248 26 -50 55 -63 127 -60 62 3
65 4 88 41 29 44 43 37 34 -17 -5 -31 -12 -41 -39 -52 -18 -8 -59 -14 -90 -14
-47 0 -63 5 -101 32 -67 48 -98 108 -97 185 0 51 5 69 29 104 65 93 155 117
263 68z m1464 21 c44 -13 46 -14 53 -71 l6 -44 -23 25 c-56 61 -75 72 -107 65
-65 -14 -72 -101 -10 -124 16 -6 43 -16 59 -22 49 -18 90 -73 90 -119 0 -51
-15 -83 -50 -103 -32 -19 -106 -22 -161 -7 -34 10 -37 14 -44 56 -9 60 7 78
29 33 35 -73 110 -95 153 -45 47 54 21 99 -79 136 -66 25 -98 61 -98 113 0 40
33 80 82 102 40 17 52 18 100 5z m-1267 -10 c3 -4 -2 -13 -10 -20 -11 -9 -15
-32 -15 -80 0 -77 3 -80 97 -80 81 0 83 2 83 86 0 43 -4 74 -10 74 -5 0 -10 5
-10 10 0 6 23 10 50 10 47 0 63 -11 40 -25 -6 -4 -10 -71 -10 -167 0 -136 3
-164 17 -180 15 -17 14 -18 -40 -18 l-55 0 14 29 c10 20 12 45 8 89 l-7 60
-76 4 c-42 3 -82 3 -88 0 -19 -6 -17 -132 3 -160 l15 -22 -65 0 c-69 0 -80 7
-46 28 19 11 20 23 20 171 0 129 -3 161 -15 171 -8 7 -15 17 -15 21 0 11 109
9 115 -1z m456 -17 c6 -16 17 -48 25 -73 34 -116 85 -254 99 -270 21 -23 10
-30 -52 -30 l-53 0 16 25 c15 22 15 28 -1 74 -17 53 -28 61 -81 61 -29 0 -36
-5 -49 -36 -17 -42 -20 -90 -5 -99 23 -14 7 -25 -35 -25 -25 0 -45 3 -45 6 0
3 8 18 19 32 18 26 114 279 127 335 3 15 10 27 15 27 5 0 14 -12 20 -27z m368
-7 c26 -24 44 -79 36 -107 -4 -12 -18 -32 -31 -44 -23 -21 -23 -24 -9 -51 8
-16 18 -45 21 -64 4 -19 16 -43 28 -52 26 -21 13 -38 -30 -41 -28 -2 -29 0
-49 76 -20 79 -43 111 -71 100 -20 -8 -19 -136 1 -157 24 -23 19 -26 -46 -26
l-60 0 20 29 c19 26 21 44 21 168 0 107 -3 144 -15 159 -8 10 -15 22 -15 26
-1 23 171 10 199 -16z m196 24 c4 -6 2 -17 -5 -25 -17 -20 -10 -337 8 -343 30
-10 7 -22 -43 -22 -50 0 -73 12 -42 23 17 6 17 331 -1 349 -6 6 -12 16 -12 20
0 12 88 10 95 -2z m645 2 c0 -2 -5 -23 -11 -48 -12 -47 -24 -56 -34 -24 -7 21
-46 37 -64 25 -7 -4 -11 -63 -11 -161 0 -128 3 -156 15 -160 34 -14 11 -24
-52 -23 -65 0 -67 1 -45 16 21 15 22 21 22 174 l0 159 -30 0 c-20 0 -35 -8
-46 -25 -24 -37 -34 -31 -34 23 l0 47 145 0 c80 0 145 -1 145 -3z m265 -2 c9
-14 15 -90 7 -90 -4 0 -18 13 -29 29 -24 32 -91 50 -105 28 -4 -7 -8 -40 -8
-74 0 -67 9 -76 46 -48 30 23 34 19 34 -40 0 -30 -2 -55 -5 -55 -3 0 -16 12
-29 27 -34 39 -46 23 -46 -63 l0 -75 47 3 c41 3 50 7 68 35 26 42 35 41 35 -1
0 -66 0 -66 -134 -66 -110 1 -119 2 -101 16 18 14 20 29 23 173 3 133 1 159
-12 170 -9 8 -16 20 -16 27 0 11 22 14 111 12 61 -1 112 -5 114 -8z m178 1 c4
-1 -2 -14 -13 -29 -19 -25 -20 -39 -18 -177 l3 -150 45 -3 c40 -3 48 0 73 29
l27 33 0 -47 0 -47 -120 0 c-73 0 -120 4 -120 10 0 6 4 10 10 10 18 0 30 76
30 196 0 107 -2 124 -20 147 l-21 26 43 3 c24 2 50 2 58 1 8 -1 19 -2 23 -2z"/>
<path d="M1331 961 c-18 -32 -10 -66 14 -66 16 0 19 6 17 30 -2 16 -7 36 -12
44 -6 11 -10 10 -19 -8z"/>
<path d="M1626 1054 c-3 -9 -6 -45 -6 -80 l0 -64 29 0 c36 0 71 40 71 80 0 54
-77 107 -94 64z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
